import React from 'react';

const Footer = () => (
    

 <div className="footer">
   <p> © 2023 Nick_Cyber_Design</p>
  </div>

  
    
);

export default Footer;